import React from "react";
import "../Components/Contact.css";
import { Link } from "react-router-dom";

const Sell = () => {
  return (
    <div>
      <div className="contact-container">
        <div className="contact-content">
          <h1>Sell </h1>
          <p>What do you want to sell</p>
          <div class="container text-center">
            <div class="row row-cols-2">
              <Link
                to="/cars"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div class="col  sell-text">
                  <i class="fa-solid icon-center fa-car"></i>Cars
                </div>
              </Link>
              <Link
                to="/properties"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div class="col sell-text">
                  <i class="fa-solid icon-center fa-building"></i> Properties
                </div>
              </Link>
              <Link to="/mobiles" style={{ textDecoration: "none", color: "black" }}>
                <div class="col sell-text">
                  <i class="fa-solid icon-center fa-mobile"></i> Mobiles
                </div>
              </Link>
              <Link to="/bikes" style={{ textDecoration: "none", color: "black" }}>
                <div class="col sell-text">
                  <i class="fa-solid icon-center fa-bicycle"></i> Bikes
                </div>
              </Link>
              <Link to="/electronics" style={{ textDecoration: "none", color: "black" }}>
                <div class="col sell-text">
                  <i class="fa-solid icon-center fa-laptop-medical"></i> Electronics
                </div>
              </Link>
              <Link to="/Jobs" style={{ textDecoration: "none", color: "black" }}>
                <div class="col sell-text">
                  <i class="fa-solid icon-center fa-suitcase"></i> Jobs{" "}
                </div>
              </Link>
              <Link to="/hostel" style={{ textDecoration: "none", color: "black" }}>
                {" "}
                <div class="col sell-text">
                  <i class="fa-solid icon-center fa-hotel"></i> Pg & Hostel{" "}
                </div>
              </Link>
              <Link to="/furniture" style={{ textDecoration: "none", color: "black" }}>
                <div class="col sell-text">
                  <i class="fa-solid icon-center fa-couch"></i> Furniture{" "}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sell;
