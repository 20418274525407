import { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../store/auth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "../Admin.css"

export const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const { authorizationToken } = useAuth();

  const getAllUsersData = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/admin/users`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  }, [authorizationToken]);

  const deleteUser = async (id) => {
    try {
      const response = await fetch(
        `https://in.quiko.in/api/admin/users/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: authorizationToken,
          },
        }
      );
      const data = await response.json();
      toast.success(data.message);
      if (response.ok) {
        getAllUsersData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsersData();
  }, [getAllUsersData]);

  return (
    <>
      <section className="admin-users-section">
        <div className="container">
          <h1>Admin Users Data</h1>
          <p> Total Registered Users: {users.length} </p>
        </div>
        <div className="container admin-users">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((curUser, index) => {
                return (
                  <tr key={index}>
                    <td>{curUser.username}</td>
                    <td>{curUser.email}</td>
                    <td>{curUser.phone}</td>
                    <td><Link to={`${curUser._id}/edit`} className="edit">Edit</Link></td>
                    <td><button  type="button" className="btn btn-danger" onClick={()=>deleteUser(curUser._id)}>Delete</button></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};
