import React,{useEffect, useState} from "react";
import "../../Components/Contact.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/auth";
import { Autocomplete, TextField, InputAdornment } from "@mui/material";


const Mobile = () => {

  const [formData, setFormData] = useState({
    email:"",
    subcate: "",
    brand: "",
    price: "",
    title: "",
    description: "",
    aria: "" ,
    wnumber:"",
  });
  const [selectedFile, setSelectedFile] = useState(null); // State for handling file upload
  const [myOptions, setMyOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        email: user.email, // Set the user's email when the component mounts
      }));
    }
  }, [user]); // Run this effect when the user changes

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Save the selected file in state
  };

  const getDataFromAPI = async (event) => {
    const value = event.target.value;
    setFormData({ ...formData, aria: value }); // Update location in formData
    setErrorMessage(""); // Clear any previous error message

    if (value.length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${value}&format=json`
        );

        if (!response.ok) {
          throw new Error("No Location Found");
        }

        const results = await response.json();
        setMyOptions(results.map((result) => result.display_name));
      } catch (error) {
        setErrorMessage("No location found"); // Set error message if fetch fails
      }
    }
  };

  const handleAutocompleteChange = (event, value) => {
    setFormData({ ...formData, aria: value || "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("image", selectedFile); // Append the selected file to the form data
    data.append("formData", JSON.stringify(formData)); // Append the form data

    try {
      const response = await fetch(`https://in.quiko.in/api/sell/mobiles`, {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        toast.success("Form submitted successfully");
        navigate("/");
      } else {
        toast.error("Failed to submit form");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };
      
  return (
    
    <div className="contact-container">
      <div className="contact-content">
        <h1>Mobiles </h1>
        <form onSubmit={handleSubmit}>
          
          {/* <div className="mb-3">
            <label htmlFor="disabledTextInput" className="form-label">
              Category
            </label>
            <input
              type="text"
              name="mobiles"
              value="mobiles"
              id="disabledTextInput"
              className="form-control"
              placeholder="Mobiles"
              disabled
            />
          </div> */}

            <div className="form-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                name="email"
                autoComplete="off"
                className="form-control"
                id="email"
                required
                value={formData.email}
              onChange={handleChange}
              />
            </div>

          <div className="mb-3">
         <select
            name="subcate"
            className="form-select form-select-sm"
            aria-label="Small select example"
            onChange={handleChange}
            required
          >
            <option selected="">Select Category*</option>
            <option value="Mobile Phones">Mobile Phones</option>
            <option value="Accessaries">Accessaries</option>
            <option value="Tablets">Tablets</option>
          </select>
         </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Brand*
            </label>
            <input
              type="text"
              name="brand"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Price*
            </label>
            <input
              type="Number"
              name="price"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Ad title*
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Description*
            </label>
            <input
              type="text"
              name="description"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="aria" className="form-label">
              Location*
            </label>
            <Autocomplete
              style={{
                width: "100%",
                height: 50,
                background: "white",
                borderRadius: 20,
              }}
              freeSolo
              autoComplete
              autoHighlight
              options={myOptions}
              onInputChange={getDataFromAPI} // Update suggestions on input change
              onChange={handleAutocompleteChange} // Set full location on selection
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  placeholder="Select your Area, State"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      height: 50,
                      borderRadius: 20,
                      border: "1px solid black",
                      fontSize: "16px",
                      fontWeight: "700",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src="images/map-icon.png"
                          alt="Search Icon"
                          style={{ width: 26, height: 30 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errorMessage && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errorMessage}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Your Whatsapp Number*
            </label>
            <input
              type="number"
              name="wnumber"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>
          
          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Upload image*
            </label>
            <input
              type="file"
              name="image"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleFileChange}
            />
          </div>

          <button type="submit" className="form-submit-button">
            Submit Post
          </button>
        </form>
      </div>
    </div>
  );
};

export default Mobile;
