import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "../Assets/Search";
// import Arrow from "../Assets/Arrow";
import "./Navbar.css";
import logo from "../olx-logo.png";
import s_logo from "../s-logo.png";
import "../index.css";
import { ThemeContext } from "../App";
import { AuthContext, useAuth } from "../store/auth";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import map_img from "../map-icon.png"

export default function Navbar() {
  const { theme, setTheme } = useContext(ThemeContext);
  const { setLocation, setSearchProduct } = useContext(AuthContext);
  const { isLoggedIn } = useAuth();

  const [myOptions, setMyOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // State to store error messages

  const getDataFromAPI = async (event) => {
    const value = event.target.value;
    setLocation(value);
    setErrorMessage(""); // Clear any previous error message

    if (value.length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${value}&format=json`
        );

        if (!response.ok) {
          throw new Error("No Location Found");
        }

        const results = await response.json();
        setMyOptions(results.map((result) => result.display_name));
      } catch (error) {
        setErrorMessage("No location Found"); // Set error message if fetch fails
      }
    }
  };

  // const handleSelectSuggestion = (suggestion) => {
  //   setLocation(suggestion);
  //   setSuggestions([]);
  // };

  const handleToggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const navigate = useNavigate();
  const handleHome = () => navigate("/");
  const handleContact = () => navigate("/contact");
  const handleProfile = () => navigate("/userprofile");
  const handleLogout = () => navigate("/logout");
  const handleSignup = () => navigate("/signup");
  const handleLogin = () => navigate("/login");
  const handleSell = () => navigate("/sell");

  const handleProductSearchChange = (event) => {
    setSearchProduct(event.target.value);
  };

  return (
    <>
      <div className="back">
        <div className="main">
          <div className="olxlogo" onClick={handleHome}>
            <img src={logo} alt="OLX Logo" />
          </div>
          <div className="buildinglogo">
            <button onClick={handleSell} type="button" className="sell-btn">
              Sell
            </button>
          </div>

          <div className={`${theme.toString()}`}>
            <button
              onClick={handleToggleTheme}
              type="button"
              className="dark-btn"
            >
              <span className="dark-span-text">
                {theme === "light" ? "Dark" : "Light"}
              </span>
            </button>
          </div>

          <div>
            <>
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <i className="fa-solid fa-bars"></i>
              </button>
              <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasRightLabel">
                    ...
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className="offcanvas-body">
                  <div className="list-group">
                    <button
                      type="button"
                      className="list-group-item list-group-item-action active"
                      aria-current="true"
                      onClick={handleHome}
                    >
                      Home
                    </button>
                    <button
                      type="button"
                      className="list-group-item list-group-item-action"
                      onClick={handleContact}
                    >
                      Contact
                    </button>
                    {isLoggedIn ? (
                      <>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleProfile}
                        >
                          Your Profile
                        </button>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleSignup}
                        >
                          Sign up
                        </button>
                        <button
                          type="button"
                          className="list-group-item list-group-item-action"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <div className="up">
          <div className="headerParentDiv">
            <span id="first-text">
              Free 10K+ Add Post for<span id="second-text"></span>
            </span>
            <div className="headerChildDiv">
              <div className="brandName">
                <img src={s_logo} alt=" Logo" style={{ height: "40px" }} />
              </div>
              {/* <div className="placeSearch">
                <Search />
                <input
                  type="text"
                  id="browser"
                  name="browser"
                  className="ali"
                  placeholder="India"
                  autoComplete="off"
                  list="browsers"
                  onChange={handleSearchChange}
                />
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                      {suggestion}
                    </li>
                  ))}
                </ul>

                <Arrow />
              </div> */}

              <Autocomplete
                style={{
                  width: 300,
                  marginLeft: "10px",
                  height: 50,
                  background: "white",
                  borderRadius: 10,
                }}
                freeSolo
                autoComplete
                autoHighlight
                options={myOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={getDataFromAPI}
                    variant="outlined"
                    // label="Search Box"
                    placeholder="Search Location Here.."
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: 50,
                        borderRadius: 10,
                        border: "1px solid black",
                        fontSize: "16px",
                        fontWeight: "700",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src="images/map-icon.png"
                            alt="Search Icon"
                            style={{ width: 26, height: 30 }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = map_img;
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {errorMessage && (
                <div style={{ color: "red", marginTop: "10px" }}>
                  {errorMessage}
                </div>
              )}
              <div className="productSearch">
                <div className="input">
                  <input
                    type="text"
                    placeholder="Find car, mobile phone and more..."
                    onChange={handleProductSearchChange}
                  />
                </div>
                <div className="searchAction">
                  <Search />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
