import React from 'react'
import "./Add.css"
// scrollToCategory home.js mathi 
export default function Category({ scrollToCategory }) {
    return (
        <div>
            <br />
            <br />
            <h1 className='category' style={{ textAlign: 'center', fontFamily: "serif", fontWeight: "bolder", display: "flex", justifyContent: "center" }} >ALL CATEGORIES </h1>
            <br />
            <br />
            <div style={{ display: 'flex', justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", textAlign: 'center' , gap:"40px"}}>

                <div onClick={() => scrollToCategory('Jobs')} className='category-icons'>
                    <img className='category-img' style={{ width: '80%' }} src="images/mobile.gif" alt="" ></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }} >Mobile</p>
                </div>
                <div  onClick={() => scrollToCategory('Car')} className='category-icons'>
                    <img className='category-img' style={{ width: '80%' }} src="images/icons8-car.gif" alt="" ></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }}> CAR </p>
                </div>
                <div onClick={() => scrollToCategory('Bikes')} className='category-icons'>
                    <img className='category-img' style={{ width: '80%' }} src="images/property.gif" alt="" ></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }} >Property</p>
                </div>

                <div onClick={() => scrollToCategory('Car')} className='category-icons'>
                    <img className='category-img' style={{ width: '80%' }} src="images/bikes.gif" alt=""></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }} >Bikes</p>
                </div>

                <div onClick={() => scrollToCategory('Property')} className='category-icons'>
                    <img className='category-img' style={{ width: '80%' }} src="images/jobs.gif" alt="" ></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }} >Jobs</p>
                </div>

                <div onClick={() => scrollToCategory('Mobile')} className='category-icons' >
                    <img className='category-img' style={{ width: '80%' }} src="images/shop.gif" alt="" ></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }} >Hostels</p>
                </div>
                <div onClick={() => scrollToCategory('Hostels')} className='category-icons'>
                    <img className='category-img' style={{ width: '60%', fontFamily: "serif", marginTop: "10px" }} src="images/electronics.gif" alt=""></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }} >Electronics</p>
                </div>
                <div onClick={() => scrollToCategory('Electronics')} className='category-icons'>
                    <img className='category-img' style={{ width: '70%' }} src="images/wood.gif" alt="" ></img>
                    <p className='category' style={{ fontWeight: "bolder", fontFamily: "serif", marginTop: "10px" }} >Furniture</p>
                </div>

            </div>
            {/* <div style={{ display: 'flex', justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap", textAlign: 'center' }}>
                <div>
                    <img style={{ width: '60%' }} src="https://www.olx.com.pk/assets/animals.62d396e85f7523dbc8ff23889fdd5c31.png" alt="" ></img>
                    <p className='category' style={{ fontWeight: 'bolder', fontFamily: "serif", marginTop: "10px" }}>ANIMALS</p>
                </div>
                <div>
                    <img style={{ width: '60%' }} src="" alt="" ></img>
                    <p className='category' style={{ fontWeight: 'bolder' }}></p>
                </div>
                <div>
                    <img style={{ width: '60%' }} src="https://www.olx.com.pk/assets/furniture-home-decor.66bcf157a53ea4c736a5b0af41219475.png" alt="" ></img>
                    <p className='category' style={{ fontWeight: 'bolder', fontFamily: "serif", marginTop: "10px" }}>FURNITURE</p>
                </div>
                <div>
                    <img style={{ width: '60%' }} src="https://www.olx.com.pk/assets/fashion-beauty.dd2cf7638c29b0e5c084a6673dd94dd7.png" alt="" ></img>
                    <p className='category' style={{ fontWeight: 'bolder', fontFamily: "serif", marginTop: "10px" }}>FASHION</p>
                </div>

                <div>
                    <img style={{ width: '60%' }} src="https://www.olx.com.pk/assets/books-sports-hobbies.6fee8d841b332d65a10f050f4a2ee1c8.png" alt="" ></img>
                    <p className='category' style={{ fontWeight: 'bolder', fontFamily: "serif", marginTop: "10px" }}>BOOKS</p>
                </div>
                <div>
                    <img style={{ width: '60%' }} src="https://www.olx.com.pk/assets/kids.cd8d8864804f1c35dd6a7df68268a48d.png" alt="" ></img>
                    <p className='category' style={{ fontWeight: 'bolder', fontFamily: "serif", marginTop: "10px" }}>KIDS</p>
                </div>
            </div> */}
        </div>
    )
}
