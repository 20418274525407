import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./Add.css"


export default function Add({ scrollToCategory }) {

  return (
    <div className="add">
      <div
        id="carouselExampleInterval"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="5000">
            <img src="./images/1ledhouse.gif" className="d-block w-sm" alt="..." />
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <img src="./images/2ledphones.gif" className="d-block w-sm" alt="..." />
          </div>
          <div className="carousel-item" data-bs-interval="5000">  
            <img src="./images/3ledcars.gif" className="d-block w-sm" alt="..." />
          </div>
          <div className="carousel-item" data-bs-interval="5000">  
            <img src="./images/4ledjob.gif" className="d-block w-sm" alt="..." />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <br />
      
      <div class="ads text-center" style={{maxHeight:"100%"}}>
        <div class="row adsrow">
          <div class="col" id="add1" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Car')}><img src="./images/anim1.gif" alt="..." /></div>
          <div class="col" id="add2" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Car')}><img src="./images/anim2.gif" alt="..." /></div>
          <div class="col" id="add7" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Bikes')}><img src="./images/anim8.gif" alt="..." /></div>
          <div class="col" id="add4" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Property')}><img src="./images/anim4.gif" alt="..." /></div>
          <div class="col" id="add5" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Mobile')}><img src="./images/anim5.gif" alt="..." /></div>
          <div class="col" id="add6" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Electronics')}><img src="./images/anim6.gif" alt="..." /></div>
          <div class="col" id="add7" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Hostels')}><img src="./images/anim7.gif" alt="..." /></div>
          <div class="col" id="add8" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Jobs')}><img src="./images/anim3.gif" alt="..." /></div>
          <div class="col" id="add9" style={{height:"250px", padding:"0px", border:"none"}} onClick={() => scrollToCategory('Car')}><img src="./images/anim9.gif" alt="..." /></div>
 
        </div>
      </div>
      {/* <div>
                <img src="./images/ad3.png" className="smimg" alt="..." />
            </div> */}
    </div>
  );
}
