import React, { useEffect, useState } from "react";
import "../../../Components/Contact.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../store/auth";
import { Autocomplete, TextField, InputAdornment } from "@mui/material";


const RentShops = () => {
  const [formData, setFormData] = useState({
    email: "",
    rent: "",
    furnishing: "",
    listedby: "",
    builtuparea: "",
    carpetarea: "",
    maintanance: "",
    parking: "",
    washrooms: "",
    facing: "",
    projectname: "",
    title: "",
    description: "",
    aria: "" ,
    wnumber:"",
  });
  const [selectedFile, setSelectedFile] = useState(null); // State for handling file upload
  const [myOptions, setMyOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        email: user.email, // Set the user's email when the component mounts
      }));
    }
  }, [user]); // Run this effect when the user changes

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Save the selected file in state
  };

  const getDataFromAPI = async (event) => {
    const value = event.target.value;
    setFormData({ ...formData, aria: value }); // Update location in formData
    setErrorMessage(""); // Clear any previous error message

    if (value.length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${value}&format=json`
        );

        if (!response.ok) {
          throw new Error("No Location Found");
        }

        const results = await response.json();
        setMyOptions(results.map((result) => result.display_name));
      } catch (error) {
        setErrorMessage("No location found"); // Set error message if fetch fails
      }
    }
  };

  const handleAutocompleteChange = (event, value) => {
    setFormData({ ...formData, aria: value || "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("image", selectedFile); // Append the selected file to the form data
    data.append("formData", JSON.stringify(formData)); // Append the form data

    try {
      const response = await fetch(`https://in.quiko.in/api/sell/rentshop`, {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        toast.success("Form submitted successfully");
        navigate("/");
      } else {
        toast.error("Failed to submit form");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-content">
        <h1>Rent : Shops & Office </h1>
        <form onSubmit={handleSubmit}>
          {/* <div className="mb-3">
            <label htmlFor="disabledTextInput" className="form-label">
              Category
            </label>
            <input
              type="text"
              name="rentshops"
              value="rentshops"
              id="disabledTextInput"
              className="form-control"
              placeholder="Rent : Shops & Office"
              disabled
            />
          </div> */}

            <div className="form-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                name="email"
                autoComplete="off"
                className="form-control"
                id="email"
                required
                value={formData.email}
              onChange={handleChange}
              />
            </div>

           <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Rent*
            </label>
            <input
              type="Number"
              name="rent"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <select
              name="furnishing"
              className="form-select form-select-sm"
              aria-label="Small select example"
              onChange={handleChange}
              required
            >
              <option selected="">Furnishing*</option>
              <option value="Furnished">Furnished</option>
              <option value="Semi-Furnished">Semi-Furnished</option>
              <option value="Unfurnished">Unfurnished</option>
            </select>
          </div>


          <div className="mb-3">
            <select
              className="form-select form-select-sm"
              aria-label="Small select example"
              name="listedby"
              onChange={handleChange}
              required
            >
              <option selected="">Listed by*</option>
              <option value="Builder">Builder</option>
              <option value="Dealer">Dealer</option>
              <option value="Owner">Owner</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Super Builtup area (ft^2)*
            </label>
            <input
              type="number"
              name="builtuparea"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Carpet area (ft^2)*
            </label>
            <input
              type="number"
              name="carpetarea"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Maintanance
            </label>
            <input
              type="number"
              name="maintanance"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <select
              className="form-select form-select-sm"
              aria-label="Small select example"
              name="parking"
              onChange={handleChange}
              required
            >
              <option selected="">Car Parking*</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="3+">3+</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Washrooms
            </label>
            <input
              type="number"
              name="washrooms"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <select
              className="form-select form-select-sm"
              aria-label="Small select example"
              name="facing"
              onChange={handleChange}
              required
            >
              <option selected="">Facing </option>
              <option value="East">East</option>
              <option value="North">North</option>
              <option value="North-East">North-East</option>
              <option value="North-West">North-West</option>
              <option value="South">South</option>
              <option value="South-East">South-East</option>
              <option value="South-West">South-West</option>
              <option value="West">West</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Project Name
            </label>
            <input
              type="text"
              name="projectname"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Ad title*
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Description*
            </label>
            <input
              type="text"
              name="description"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>


          <div className="mb-3">
            <label htmlFor="aria" className="form-label">
              Location*
            </label>
            <Autocomplete
              style={{
                width: "100%",
                height: 50,
                background: "white",
                borderRadius: 20,
              }}
              freeSolo
              autoComplete
              autoHighlight
              options={myOptions}
              onInputChange={getDataFromAPI} // Update suggestions on input change
              onChange={handleAutocompleteChange} // Set full location on selection
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  placeholder="Select your Area, State"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      height: 50,
                      borderRadius: 20,
                      border: "1px solid black",
                      fontSize: "16px",
                      fontWeight: "700",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src="images/map-icon.png"
                          alt="Search Icon"
                          style={{ width: 26, height: 30 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errorMessage && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errorMessage}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Your Whatsapp Number*
            </label>
            <input
              type="number"
              name="wnumber"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>          <div className="mb-3">
            <label htmlFor="exampleInputNumber" className="form-label">
              Your Whatsapp Number*
            </label>
            <input
              type="number"
              name="wnumber"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleChange}
            />
          </div>
          
          <div className="mb-3">
            <label htmlFor="exampleInputText" className="form-label">
              Upload image*
            </label>
            <input
              type="file"
              name="image"
              className="form-control"
              // id="exampleInputNumber"
              required
              onChange={handleFileChange}
            />
          </div>

          <button type="submit" className="form-submit-button">
            Submit Post
          </button>
        </form>
      </div>
    </div>
  );
};

export default RentShops;
