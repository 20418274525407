import React, { useState } from 'react'
import CarsAdmin from './Admin-sell/Cars _Admin'
import BikesAdmin from './Admin-sell/Bike_Admin';
import HostelsAdmin from './Admin-sell/Hostels_Admin';
import JobsAdmin from './Admin-sell/Jobs_Admin';
import MobilesAdmin from './Admin-sell/Mobiles_Admin';
import ElectronicsAdmin from './Admin-sell/Electronics_Admin';
import FurnituresAdmin from './Admin-sell/Furnitures_Admin';
import RenthouseAdmin from './Admin-sell/Renthouse_Admin';
import SalehouseAdmin from './Admin-sell/Salehouse_Admin';
import RentshopAdmin from './Admin-sell/Rentshop_Admin';
import SaleshopAdmin from './Admin-sell/Saleshop_Admin';
import PlotsAdmin from './Admin-sell/Plots_Admin';

const AdminSell = () => {
    const [totalCarsPosts, setTotalCarsPosts] = useState(0);
    const [totalBikesPosts, setTotalBikesPosts] = useState(0);
    const [totalHostelPosts, setTotalHostelsPosts] = useState(0);
    const [totalJobsPosts, setTotalJobsPosts] = useState(0);
    const [totalMobilePosts, setTotalMobilePosts] = useState(0);
    const [totalElectronicsPosts, setTotalElectronicsPosts] = useState(0);
    const [totalFurnituresPosts, setTotalFurnituresPosts] = useState(0);
    const [totalRenthousePosts, setTotalRenthousePosts] = useState(0);
    const [totalSalehousePosts, setTotalSalehousePosts] = useState(0);
    const [totalRentshopPosts, setTotalRentshopPosts] = useState(0);
    const [totalSaleshopPosts, setTotalSaleshopPosts] = useState(0);
    const [totalPlotsPosts, setTotalPlotsPosts] = useState(0);

  return (
    <>
    <div className="container">
    <h1>Admin Sell Pots </h1>
    <p> Total Post - Car : {totalCarsPosts}, Bikes : {totalBikesPosts}, Hostels : {totalHostelPosts}, Jobs : {totalJobsPosts}, Mobiles : {totalMobilePosts}, Electronics : {totalElectronicsPosts}, Furnitures : {totalFurnituresPosts}, Renthouse : {totalRenthousePosts}, Salehouse : {totalSalehousePosts}, Rentshop : {totalRentshopPosts}, Saleshop : {totalSaleshopPosts}, Plots : {totalPlotsPosts} </p>
  </div>
    <CarsAdmin setTotalPosts={setTotalCarsPosts}/>
    <BikesAdmin setTotalPosts={setTotalBikesPosts}/>
    <HostelsAdmin setTotalPosts={setTotalHostelsPosts}/>
    <JobsAdmin setTotalPosts={setTotalJobsPosts}/>
    <MobilesAdmin setTotalPosts={setTotalMobilePosts}/>
    <ElectronicsAdmin setTotalPosts={setTotalElectronicsPosts}/>
    <FurnituresAdmin setTotalPosts={setTotalFurnituresPosts}/>
    <RenthouseAdmin setTotalPosts={setTotalRenthousePosts}/>
    <SalehouseAdmin setTotalPosts={setTotalSalehousePosts}/>
    <RentshopAdmin setTotalPosts={setTotalRentshopPosts}/>
    <SaleshopAdmin setTotalPosts={setTotalSaleshopPosts}/>
    <PlotsAdmin setTotalPosts={setTotalPlotsPosts}/>
    </>
  )
}

export default AdminSell
