import React, { useCallback, useContext, useState } from 'react'
import { useEffect } from 'react';
import {AuthContext } from "../../../../store/auth"
import "../../Admin.css"
import { toast } from 'react-toastify';

const SalehouseAdmin = ({ setTotalPosts }) => {
  const [car, setCar] = useState([]);
  const { location, searchProduct, user } = useContext(AuthContext);
  // const [products, setProducts] = useState([]);

  const getCar = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/salehouse`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        setCar(data.response_data);
        setTotalPosts(data.response_data.length);  // Set total number of posts
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [setTotalPosts]);

  useEffect(() => {
    getCar();
  }, [getCar]);

  const deleteCar = useCallback(async (id) => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/salehouse/delet/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`, // If your API requires token authentication
        },
      });
      if (response.ok) {
        setCar((prevProducts) => prevProducts.filter((product) => product._id !== id));
        toast.success("Delet successfull")

      } else {
        console.log("Failed to delete the car.");
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [user]);
  
  const filteredCars = car.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
        (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title &&
        curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
        (curEle.brand &&
          curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  });

  return (
    <section className="admin-users-section">
       
        <div className="list-group contact-box">
    
        {filteredCars.map((curEle, index) => {
            const {_id, brand, title, imageUrl, price, aria, email } = curEle;
             return ( 
                  <div
                    key={index}
                    className="  active col single-box admin-sell-card"
                    aria-current="true"
                  >
                    <div className=" w-100 ">
                      <img src={`https://in.quiko.in/${imageUrl}`} className='admin-sell-Img' alt='imge loding..'/>
                      <h5 className="mb-1"> {title}</h5>
                      
                    </div>
                    <small className="sell-email">{email}</small>
                    <p className="mb-1 admin-sell-text">Barnd : {brand}</p>
                    <p className="mb-1 admin-sell-text"> Price : {price}</p>
                    <p className="mb-1 admin-sell-text">{aria}</p>
                    
                    <div>
                    <small >
                        <button
                          className="btn btn-danger"
                          onClick={() => deleteCar(_id)}
                        //   onClick={() => deleteContactById(_id)}
                        >
                          delete
                        </button>
                      </small>
                      <small style={{marginLeft:"10px"}}>
                        <button
                          className="btn btn-primary"
                          
                        //   onClick={() => deleteContactById(_id)}
                        >
                          Edit
                        </button>
                      </small>
                    </div>
                  </div>
                  
           
            );
          })} 
  
        </div>
      </section>
  )
}

export default SalehouseAdmin
