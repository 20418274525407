import React from "react";
import "../../Components/Contact.css";
import { Link } from "react-router-dom";

const Properties = () => {
  return (
    <div>
      <div className="contact-container">
        <div className="contact-content">
          <h1>Properties </h1>
          <p>What do you want to sell</p>
          <div class="container text-center">
            <div class="row row-cols-2">
              <Link
                to="/salehouse"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div class="col">
                <i class="fa-solid fa-money-bill"></i> Sale: Housees & Apartment
                </div>
              </Link>
              <Link
                to="/renthouse"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div class="col">
                  <i class="fa-solid fa-building"></i> Rent: Houses & Apartment
                </div>
              </Link>
              <Link to="/plots" style={{ textDecoration: "none", color: "black" }}>
                <div class="col">
                  <i class="fa-solid fa-house"></i> Lands & plots
                </div>
              </Link>
              <Link to="/rentshops" style={{ textDecoration: "none", color: "black" }}>
                <div class="col">
                <i class="fa-solid fa-money-bill"></i>Rent: Shops & Offices
                </div>
              </Link>

              <Link to="/saleshops" style={{ textDecoration: "none", color: "black" }}>
                <div class="col">
                  <i class="fa-solid fa-shop"></i> Sale : Shops & Offices
                </div>
              </Link>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Properties;
