import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
// import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../store/auth"; // Adjust the import path as necessary
import { toast } from "react-toastify";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function ManageHostel() {
  const [products, setProducts] = useState([]);
  const { user } = useAuth(); // Get the current user data

  const getHostel = useCallback(async () => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/hostels`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        // Filter the products to only include those with the user's email
        const filteredProducts = data.response_data.filter(
          (product) => product.email === user.email
        );
        setProducts(filteredProducts);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [user]);

  const deleteHostel = useCallback(async (id) => {
    try {
      const response = await fetch(`https://in.quiko.in/api/sell/hotsels/delet/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`, // If your API requires token authentication
        },
      });
      if (response.ok) {
        setProducts((prevProducts) => prevProducts.filter((product) => product._id !== id));
        toast.success("Delet successfull")

      } else {
        console.log("Failed to delete the hotstel and pg.");
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  }, [user]);


  useEffect(() => {
    if (user) {
      getHostel();
    }
  }, [user, getHostel]);

  return (
    
          products.map((curEle, index) => {
            const { _id, title, imageUrl, rent,  aria, description } =
              curEle;
            return (
              <>
                <Grid item key={index} marginBottom={1}>
                  <ButtonBase sx={{ width: 128, height: 128 }}>
                    <Img
                      alt="complex"
                      src={`https://in.quiko.in/${imageUrl}`}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        {title}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {description}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Loaction: {aria}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ cursor: "pointer",backgroundColor:"#e9e9e9" , textAlign:"center" }} variant="body2" onClick={() => deleteHostel(_id)}>
                        Remove
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" component="div">
                    ₹{rent}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            );
          }) 

    
  );
}
