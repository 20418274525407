import React, { useRef } from 'react'
import Add from '../Components/Add'
import Category from '../Components/Category'
import EndAdd from '../Components/EndAdd'
import HomeCard from '../Components/HomeCard'
import HomeCard1 from '../Components/HomeCard1'
import HomeCard2 from '../Components/HomeCard2'
import HomeCard3 from '../Components/HomeCard3'
import HomeCard4 from '../Components/HomeCard4'
import HomeCard5 from '../Components/HomeCard5'
import HomeCard6 from '../Components/HomeCard6'
import HomeCard7 from '../Components/HomeCard7'
// import HomeCard8 from '../Components/HomCard8'
import BannerAdd from '../Components/BannerAdd'




export default function Home() {
    const categoryRefs = {
        Mobile: useRef(null),
        Car: useRef(null),
        Property: useRef(null),
        Bikes: useRef(null),
        Jobs: useRef(null),
        Hostels: useRef(null),
        Electronics: useRef(null),
        Furniture: useRef(null),

    };

    const scrollToCategory = (category) => {
        const ref = categoryRefs[category];
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div>
            <Add  scrollToCategory={scrollToCategory}/>
            <Category scrollToCategory={scrollToCategory} />
            <br />
            <br />
            <div ref={categoryRefs.Car}><HomeCard /></div>
            <div ref={categoryRefs.Bikes}><HomeCard1 /></div>
            <div ref={categoryRefs.Property}><HomeCard2 /></div>
            <div ref={categoryRefs.Jobs}><HomeCard3 /></div>
            <div ref={categoryRefs.Mobile}><HomeCard4 /></div>
            <div ref={categoryRefs.Hostels}><HomeCard5 /></div>
            <div ref={categoryRefs.Electronics}><HomeCard6 /></div>
            <div ref={categoryRefs.Furniture}><HomeCard7 /></div>
            {/* <HomeCard8 /> */}

            <br />
            <br />
            <EndAdd />
            <br />
            <BannerAdd />
        </div>
    )
}
