import React, { createContext, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "./store/auth";
import Delivery from "./Pages/Delivery";
// import Reviews from "./Pages/Reviews";
import Contact from "./Pages/Contact";
import Sell from "./Pages/Sell";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import LoginForm from "./Pages/LoginForm";
import SignupForm from "./Pages/signupForm";
import ErrorPage from "./Pages/ErrorPage";
import Cars from "./Pages/Category/Cars";
import Mobile from "./Pages/Category/Mobile";
import Bikes from "./Pages/Category/Bikes";
import Electronics from "./Pages/Category/Electronics";
import Furniture from "./Pages/Category/Furniture";
import Hostels from "./Pages/Category/Hostels";
import Properties from "./Pages/Category/Properties";
import Jobs from "./Pages/Category/Jobs";
import { Logout } from "./Pages/Logout";
import SaleHouse from "./Pages/Category/Properties/SaleHouse";
import RentHouse from "./Pages/Category/Properties/RentHouse";
import Plots from "./Pages/Category/Properties/Plots";
import RentShops from "./Pages/Category/Properties/RentShops";
import SaleShops from "./Pages/Category/Properties/SaleShops";
import ProtectedRoute from "./store/ProtectedRoute";
import CardDetails from "./Card/CardDetails/CarDetails";
import BikeDetails from "./Card/CardDetails/BikeDetails";
import JobsDetails from "./Card/CardDetails/JobsDetails";
import MobileDetails from "./Card/CardDetails/MobileDetaails";
import HostelsDetails from "./Card/CardDetails/HostelsDetails";
import ElectronicDetails from "./Card/CardDetails/ElectronicDetails";
import FurnitureDetails from "./Card/CardDetails/FurnitureDetails";
import RentHouseDetails from "./Card/CardDetails/RentHouseDetails";
import SaleHouseDetails from "./Card/CardDetails/SaleHouseDetails";
import RentShopDetails from "./Card/CardDetails/RentShopDetails";
import SaleShopDetails from "./Card/CardDetails/SaleShopDetails";
import PlotsDetails from "./Card/CardDetails/PlotsDetails";
import YourPost from "./Pages/YourPost";
import { AdminLayout } from "./Pages/admin/AdminLayout";
import { AdminUser } from "./Pages/admin/admin-pages/AdminUser";
import { AdminContact } from "./Pages/admin/admin-pages/AdminContact";
import { AdminUpdate } from "./Pages/admin/admin-pages/Admin-Update";
import AdminSell  from "./Pages/admin/admin-pages/Admin-Sell";
import UserProfile from "./Pages/UserProfile";

export const ThemeContext = createContext("null");

function App() {
  const [text, setText] = useState("Enable Dark Mode");

  function updateText() {
    setText((curr) =>
      curr === "Enable Light Mode" ? "Enable Dark Mode" : "Enable Light Mode"
    );
  }

  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  const twoFunc = () => {
    toggleTheme();
    updateText();
  };

  const AppContent = () => {
    const location = useLocation();
    const hideNavbarFooter =
      location.pathname === "/login" || location.pathname === "/signup"
      

    return (
      <>
        {!hideNavbarFooter && <Navbar />}
        <div className="form-check form-switch" >
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onClick={twoFunc}
          />
          <p className="white" onClick={updateText}>
            {text}
          </p>
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/sell" element={<Sell />} />

          <Route
            path="/delivery"
            element={
              <ProtectedRoute>
                <Delivery />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/reviews"
            element={
              <ProtectedRoute>
                <Reviews />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/contact"
            element={
              <ProtectedRoute>
                <Contact />
              </ProtectedRoute>
            }
          />
          <Route
            path="/yourpost"
            element={
              <ProtectedRoute>
                <YourPost />
              </ProtectedRoute>
            }
          />
          <Route
            path="/userprofile"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
           >
           </Route>

          <Route
            path="/cars"
            element={
              // <ProtectedRoute>
              <Cars />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/mobiles"
            element={
              // <ProtectedRoute>
              <Mobile />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/bikes"
            element={
              // <ProtectedRoute>
              <Bikes />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/electronics"
            element={
              // <ProtectedRoute>
              <Electronics />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/furniture"
            element={
              // <ProtectedRoute>
              <Furniture />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/hostel"
            element={
              // <ProtectedRoute>
              <Hostels />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/properties"
            element={
              // <ProtectedRoute>
              <Properties />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/jobs"
            element={
              // <ProtectedRoute>
              <Jobs />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/salehouse"
            element={
              // <ProtectedRoute>
              <SaleHouse />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/renthouse"
            element={
              // <ProtectedRoute>
              <RentHouse />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/plots"
            element={
              // <ProtectedRoute>
              <Plots />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/rentshops"
            element={
              // <ProtectedRoute>
              <RentShops />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/saleshops"
            element={
              // <ProtectedRoute>
              <SaleShops />
              // </ProtectedRoute>
            }
          />
          {/* ..........card detalis ........start......................... */}

          <Route
            path="/car/:id"
            element={
              <ProtectedRoute>
                <CardDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/bikes/:id"
            element={
              <ProtectedRoute>
                <BikeDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/jobs/:id"
            element={
              <ProtectedRoute>
                <JobsDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/mobiles/:id"
            element={
              <ProtectedRoute>
                <MobileDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/hostels/:id"
            element={
              <ProtectedRoute>
                <HostelsDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/electronics/:id"
            element={
              <ProtectedRoute>
                <ElectronicDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/furnitures/:id"
            element={
              <ProtectedRoute>
                <FurnitureDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/renthouse/:id"
            element={
              <ProtectedRoute>
                <RentHouseDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/salehouse/:id"
            element={
              <ProtectedRoute>
                <SaleHouseDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/rentshop/:id"
            element={
              <ProtectedRoute>
                <RentShopDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/saleshop/:id"
            element={
              <ProtectedRoute>
                <SaleShopDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/plots/:id"
            element={
              <ProtectedRoute>
                <PlotsDetails />
              </ProtectedRoute>
            }
          ></Route>

          {/* ..........card detalis ........end......................... */}

          {/* .................--- admin  --...................... */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            <Route path="users" element={<AdminUser />}  />
            <Route path="users/:id/edit" element={<AdminUpdate />} />
            
            <Route path="contacts" element={<AdminContact />} />
            <Route path="sell" element={<AdminSell />} />
          </Route>
        </Routes>
        {!hideNavbarFooter && <Footer />}
      </>
    );
  };

  return (
    <div id={theme}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <AuthProvider>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </AuthProvider>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
