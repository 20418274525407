import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import "../Components/Contact.css"
import "./CardDetails.css";

function CardDetails() {
  const { id } = useParams();
  const [car, setCar] = useState(null);

  useEffect(() => {
    const getCarDetails = async () => {
      try {
        const response = await fetch(
          `https://in.quiko.in/api/sell/cars/${id}`,
          {
            method: "GET",
          }
        );
        if (response.ok) {
          const data = await response.json();
          setCar(data.response_data);
        }
      } catch (error) {
        console.log("Error fetching car details:", error);
      }
    };

    getCarDetails();
  }, [id]);

  if (!car) {
    return <div>Loading...</div>;
  }

  const {
    imageUrl,
    brand,
    price,
    year,
    fuel,
    kmDriven,
    transmission,
    owners,
    title,
    description,
    state,
    aria,
    wnumber,
  } = car;

  return (
    <>
      {/* <div className="contact-container">
        <div className="contact-container">
            <h1>this is car</h1>
          <div>
            <h1>{title}</h1>
            <img src={`https://in.quiko.in/${imageUrl}`} alt={title} />
            <h2>Brand: {brand}</h2>
            <h3>Price: Rs {price}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div> */}

      <>
        <section className="product">
          <div className="product__photo">
            <div className="photo-container">
              <img
                src={`https://in.quiko.in/${imageUrl}`}
                alt="green apple slice"
              />
            </div>
          </div>
          <div className="product__info">
            <div className="title">
              <h1>{title}</h1>
              <span>Car</span>
            </div>
            <div className="price">
              R$ <span style={{ fontSize: "40px" }}>{price}</span>
            </div>
            {/* <div className="variant">
        <h3>SELECT A COLOR</h3>
        <ul>
          <li>
            <img
              src="https://res.cloudinary.com/john-mantas/image/upload/v1537302064/codepen/delicious-apples/green-apple2.png"
              alt="green apple"
            />
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/john-mantas/image/upload/v1537302752/codepen/delicious-apples/yellow-apple.png"
              alt="yellow apple"
            />
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/john-mantas/image/upload/v1537302427/codepen/delicious-apples/orange-apple.png"
              alt="orange apple"
            />
          </li>
          <li>
            <img
              src="https://res.cloudinary.com/john-mantas/image/upload/v1537302285/codepen/delicious-apples/red-apple.png"
              alt="red apple"
            />
          </li>
        </ul>
      </div> */}
            <div className="description">
              <h3>Brand : {brand}</h3>
              <p>{description}</p>
              <ul>
                <li>Year : {year}</li>
                <li>Fual : {fuel}</li>
                <li>KMDriven : {kmDriven}</li>
                <li>Transmission : {transmission}</li>
                <li>Owners : {owners}</li>
                <li>
                  Location : {state}, {aria}
                </li>
              </ul>
            </div>
            <a
              href={` https://wa.me/${wnumber}?I'm%20interested%20in%20your%20car%20for%20sale`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
  
            >
              <button className="buy--btn">Make a Chat </button>
            </a>
          </div>
        </section>
      </>
    </>
  );
}

export default CardDetails;
