import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../store/auth";


function ScrollableCards() {
  const scrollImagesRef = useRef(null);
  const [scrollLength, setScrollLength] = useState(0);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);

  const { location, searchProduct } = useContext(AuthContext);

  const [renthouse, setRentHouse] = useState([]);
  const getRentHouse = async () => {
    try {
      const Response = await fetch(`https://in.quiko.in/api/sell/renthouse`, {
        method: "GET",
      });
      if (Response.ok) {
        const data = await Response.json();
        setRentHouse(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  };

  const [salehouse, setSaleHouse] = useState([]);
  const getSaleHouse = async () => {
    try {
      const Response = await fetch(`https://in.quiko.in/api/sell/salehouse`, {
        method: "GET",
      });
      if (Response.ok) {
        const data = await Response.json();
        setSaleHouse(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  };

  const [RentShop, setRentShop] = useState([]);
  const getRentShop = async () => {
    try {
      const Response = await fetch(`https://in.quiko.in/api/sell/rentshop`, {
        method: "GET",
      });
      if (Response.ok) {
        const data = await Response.json();
        setRentShop(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  };

  const [SaleShop, setSaleShop] = useState([]);
  const getSaleShop = async () => {
    try {
      const Response = await fetch(`https://in.quiko.in/api/sell/saleshop`, {
        method: "GET",
      });
      if (Response.ok) {
        const data = await Response.json();
        setSaleShop(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  };

  const [Plots, setPlots] = useState([]);
  const getPlots = async () => {
    try {
      const Response = await fetch(`https://in.quiko.in/api/sell/plots`, {
        method: "GET",
      });
      if (Response.ok) {
        const data = await Response.json();
        setPlots(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  };

  useEffect(() => {
    getRentHouse();
    getSaleHouse();
    getRentShop();
    getSaleShop();
    getPlots();

    const scrollImages = scrollImagesRef.current;
    const updateScrollLength = () => {
      if (scrollImages) {
        setScrollLength(scrollImages.scrollWidth - scrollImages.clientWidth);
      }
    };
    updateScrollLength();
    window.addEventListener("resize", updateScrollLength);
    return () => {
      window.removeEventListener("resize", updateScrollLength);
    };
  }, []);

  useEffect(() => {
    const scrollImages = scrollImagesRef.current;
    const leftButton = leftButtonRef.current;
    const rightButton = rightButtonRef.current;

    const checkScroll = () => {
      if (scrollImages) {
        const currentScroll = scrollImages.scrollLeft;
        if (currentScroll === 0) {
          leftButton.setAttribute("disabled", "true");
          rightButton.removeAttribute("disabled");
        } else if (currentScroll === scrollLength) {
          rightButton.setAttribute("disabled", "true");
          leftButton.removeAttribute("disabled");
        } else {
          leftButton.removeAttribute("disabled");
          rightButton.removeAttribute("disabled");
        }
      }
    };

    if (scrollImages) {
      scrollImages.addEventListener("scroll", checkScroll);
      return () => {
        scrollImages.removeEventListener("scroll", checkScroll);
      };
    }
  }, [scrollLength]);

  const leftScroll = () => {
    if (scrollImagesRef.current) {
      scrollImagesRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const rightScroll = () => {
    if (scrollImagesRef.current) {
      scrollImagesRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const navigate = useNavigate()

  const handleRentHouse = (_id) => {
    navigate(`/renthouse/${_id}`);
  };
  const handleSaleHouse = (_id) => {
    navigate(`/salehouse/${_id}`);
  };
  const handleRentShop = (_id) => {
    navigate(`/rentshop/${_id}`);
  };
  const handleSaleShop = (_id) => {
    navigate(`/saleshop/${_id}`);
  };
  const handlePlots = (_id) => {
    navigate(`/plots/${_id}`);
  };

  const filteredRentHouse = renthouse.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
      (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title && curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
      (curEle.brand && curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  });
  const filteredSaleHouse = salehouse.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
      (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title && curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
      (curEle.brand && curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  });
  const filteredRentShop = RentShop.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
      (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title && curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
      (curEle.brand && curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  });
  const filteredSaleShop = SaleShop.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
      (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title && curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
      (curEle.brand && curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  });
  const filteredPlots = Plots.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
      (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title && curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
      (curEle.brand && curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  });

  return (
    <div className="cover">
      <h1 className="homecard-title bikes" id="homecard-text">Houses</h1>

      <button ref={leftButtonRef} className="left" onClick={leftScroll}>
        <i className="fas fa-angle-double-left"></i>
      </button>
      <div ref={scrollImagesRef} className="scroll-images">

        {filteredRentHouse.map((curEle, index) => {
          const { title, imageUrl, rent, _id,  aria } = curEle;

          return (
            <div className="child" key={index} onClick={() => handleRentHouse(_id)}>
              <img src={`https://in.quiko.in/${imageUrl}`} alt="Card 1" />
              <p>For Rent</p>
              <h4 className="">Rs {rent}</h4>
              <h5>{title}</h5>
              <h6>Loaction: {aria}</h6>
              <h6>Clicked For More Update</h6>
            </div>
          );
        })}

        {filteredSaleHouse.map((curEle, index) => {
          const { title, imageUrl, rent, _id,  aria } = curEle;

          return (
            <div className="child" key={index} onClick={() => handleSaleHouse(_id)}>
              <img src={`https://in.quiko.in/${imageUrl}`} alt="Card 1" />
              <p>For Sale</p>
              <h4 className="">Rs {rent}</h4>
              <h5>{title}</h5>
              <h6>Loaction: {aria}</h6>
              <h6>Clicked For More Update</h6>
            </div>
          );
        })}

        {filteredRentShop.map((curEle, index) => {
          const {title, imageUrl, rent, _id,  aria } = curEle;

          return (
            <div className="child" key={index} onClick={() => handleRentShop(_id)}>
              <img src={`https://in.quiko.in/${imageUrl}`} alt="Card 1" />
              <p>For Rent</p>
              <h4 className="">Rs {rent}</h4>
              <h5>{title}</h5>
              <h6>Loaction: {aria}</h6>
              <h6>Clicked For More Update</h6>
            </div>
          );
        })}

        {filteredSaleShop.map((curEle, index) => {
          const {  title, imageUrl, rent, _id , aria} = curEle;

          return (
            <div className="child" key={index} onClick={() => handleSaleShop(_id)}>
              <img src={`https://in.quiko.in/${imageUrl}`} alt="Card 1" />
              <p>For Sale</p>
              <h4 className="">Rs {rent}</h4>
              <h5>{title}</h5>
              <h6>Loaction: {aria}</h6>
              <h6>Clicked For More Update</h6>
            </div>
          );
        })}
        {filteredPlots.map((curEle, index) => {
          const { type, title, imageUrl, rent, _id , aria} = curEle;

          return (
            <div className="child" key={index} onClick={() => handlePlots(_id)}>
              <img src={`https://in.quiko.in/${imageUrl}`} alt="Card 1" />
              <p>{type}</p>
              <h4 className="">Rs {rent}</h4>
              <h5>{title}</h5>
              <h6>Loaction: {aria}</h6>
              <h6>Clicked For More Update</h6>
            </div>
          );
        })}


      </div>
      <button ref={rightButtonRef} className="right" onClick={rightScroll}>
        <i className="fas fa-angle-double-right"></i>
      </button>
    </div>
  );
}

export default ScrollableCards;
