import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../store/auth";
import "./HomeCard.css";

function ScrollableCards() {
  const scrollImagesRef = useRef(null);
  const [scrollLength, setScrollLength] = useState(0);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);

  const [car, setCar] = useState([]);
  const { location, searchProduct } = useContext(AuthContext);

  const getCar = async () => {
    try {
      const Response = await fetch(`https://in.quiko.in/api/sell/cars`, {
        method: "GET",
      });
      if (Response.ok) {
        const data = await Response.json();
        setCar(data.response_data);
      }
    } catch (error) {
      console.log("service page error ", error);
    }
  };

  useEffect(() => {
    getCar();
    const scrollImages = scrollImagesRef.current;
    const updateScrollLength = () => {
      if (scrollImages) {
        setScrollLength(scrollImages.scrollWidth - scrollImages.clientWidth);
      }
    };
    updateScrollLength();
    window.addEventListener("resize", updateScrollLength);
    return () => {
      window.removeEventListener("resize", updateScrollLength);
    };
  }, []);

  useEffect(() => {
    const scrollImages = scrollImagesRef.current;
    const leftButton = leftButtonRef.current;
    const rightButton = rightButtonRef.current;

    const checkScroll = () => {
      if (scrollImages) {
        const currentScroll = scrollImages.scrollLeft;
        if (currentScroll === 0) {
          leftButton.setAttribute("disabled", "true");
          rightButton.removeAttribute("disabled");
        } else if (currentScroll === scrollLength) {
          rightButton.setAttribute("disabled", "true");
          leftButton.removeAttribute("disabled");
        } else {
          leftButton.removeAttribute("disabled");
          rightButton.removeAttribute("disabled");
        }
      }
    };

    if (scrollImages) {
      scrollImages.addEventListener("scroll", checkScroll);
      return () => {
        scrollImages.removeEventListener("scroll", checkScroll);
      };
    }
  }, [scrollLength]);

  const leftScroll = () => {
    if (scrollImagesRef.current) {
      scrollImagesRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const rightScroll = () => {
    if (scrollImagesRef.current) {
      scrollImagesRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const navigate = useNavigate();
  const handleCardClick = (_id) => {
    navigate(`/car/${_id}`);
  };

  const filteredCars = car.filter((curEle) => {
    const lowerLocation = location.toLowerCase();
    const lowerSearchProduct = searchProduct.toLowerCase();
    return (
      ((curEle.state && curEle.state.toLowerCase().includes(lowerLocation)) ||
        (curEle.aria && curEle.aria.toLowerCase().includes(lowerLocation))) &&
      ((curEle.title &&
        curEle.title.toLowerCase().includes(lowerSearchProduct)) ||
        (curEle.brand &&
          curEle.brand.toLowerCase().includes(lowerSearchProduct)))
    );
  });

  return (
    <>
      <div className="cover">

          <h1 className="homecard-title bikes" id="homecard-text">Cars</h1>

        <button ref={leftButtonRef} className="left" onClick={leftScroll}>
          <i className="fas fa-angle-double-left"></i>
        </button>
        <div ref={scrollImagesRef} className="scroll-images">
          {filteredCars.map((curEle, index) => {
            const { _id, brand, title, imageUrl, price, aria } = curEle;

            return (
              <div
                className="child"
                key={index}
                onClick={() => handleCardClick(_id)}
              >
                <img src={`https://in.quiko.in/${imageUrl}`} alt="Card 1" />
                <h4 className="">Rs {price}</h4>
                <p>{brand}</p>
                <h5>{title}</h5>
                <h6>Location: {aria}</h6>
                <h6>Clicked For More Update</h6>
              </div>
            );
          })}
        </div>
        <button ref={rightButtonRef} className="right" onClick={rightScroll}>
          <i className="fas fa-angle-double-right"></i>
        </button>
      </div>
    </>
  );
}

export default ScrollableCards;
