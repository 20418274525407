import * as React from "react";
// import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// import ButtonBase from "@mui/material/ButtonBase";
import ManageCar from "../Components/ManagePost/ManageCar";
import ManageBikes from "../Components/ManagePost/ManageBikes";
import ManageMobile from "../Components/ManagePost/ManageMobile";
import ManageJobs from "../Components/ManagePost/ManageJobs";
import ManageFurniture from "../Components/ManagePost/ManageFurniture";
import ManageElectronics from "../Components/ManagePost/ManageElectronics";
import ManageHostel from "../Components/ManagePost/ManageHostel";
import ManageRentHouse from "../Components/ManagePost/ManageRentHouse";
import ManageSaleHouse from "../Components/ManagePost/ManageSaleHouse";
import ManageRentShop from "../Components/ManagePost/ManageRentShop";
import ManagePlots from "../Components/ManagePost/ManagePlots";
import ManageSaleShop from "../Components/ManagePost/ManageSaleShop";


// import { useEffect, useState, useCallback } from "react";
// import { useAuth } from "../store/auth"; // Adjust the import path as necessary

// const Img = styled("img")({
//   margin: "auto",
//   display: "block",
//   maxWidth: "100%",
//   maxHeight: "100%",
// });

export default function YourPost() {
 

  return (
    <Paper
      sx={{
        p: 2,
        margin: "auto",
        maxWidth: 500,
        flexGrow: 1,
        marginTop: 30,
        marginBottom: 2,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container spacing={10}>
          <ManageCar  />
          <ManageBikes  />
          <ManageMobile/>
          <ManageJobs/>
          <ManageFurniture/>
          <ManageElectronics/>
          <ManageHostel/>
          <ManageRentHouse/>
          <ManageSaleHouse/>
          <ManageRentShop/>
          <ManageSaleShop/>
          <ManagePlots/>
      </Grid>
    </Paper>
  );
}
