import { Outlet, useNavigate } from "react-router-dom";
import { FaUser, FaHome,FaShoppingBag  } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import "./Admin.css"


export const AdminLayout = () => {
    const navigate = useNavigate();
    function handleUsers (){
        navigate("/admin/users")
    }
    function handleContact (){
        navigate("/admin/contacts")
    }
    function handleSell (){
        navigate("/admin/sell")
    }
    function handleHome (){
        navigate("/")
    }
  return (
    <>
      <nav className="navbar navbar-expand-lg  bg-primary" data-bs-theme="dark" style={{marginTop: "200px"}}>
        <div className="container-fluid">
        <p className="admin">Admin</p>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="list-group list-group-horizontal">
              <li className="list-group-item" onClick={handleUsers}>
                 <FaUser/> Users
              </li>
              <li className="list-group-item" onClick={handleContact}>
                 <FaMessage/> Contact
              </li>
              <li className="list-group-item" onClick={handleSell}>
                 <FaShoppingBag /> Sell
              </li>
              <li className="list-group-item" onClick={handleHome}>
                 <FaHome/> Home
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
};
